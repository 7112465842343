<template>
  <div style="width: 100%" class="d-flex flex-column">
    <v-img
      id="imginile"
      contain
      :width="
        !currentImg.includes('4jD/pi')
          ? displaySize(600, 0.05)
          : displaySize(430, 0.05)
      "
      :height="displaySize(500, 0.05)"
      class="ma-auto imgAnimation"
      :class="[`mt-${Math.ceil(displaySize(14, 0.1))}`]"
      :src="currentImg"
      @click="getImg"
    >
      <v-img
        v-if="ledImg.length > 0 && currentImg.includes('4jD/pi')"
        contain
        class="ma-auto mt-14"
        :width="displaySize(30, 0.05)"
        :height="displaySize(20, 0.05)"
        :src="ledImg"
      ></v-img>
    </v-img>
    <v-btn
      v-if="currentImg !== html[1]"
      :class="isMobile ? '' : 'mt-4'"
      :style="isMobile ? '' : 'margin-left: 20%'"
      color="#363938"
      small
      fab
      dark
      @click="backImage"
    >
      <v-icon>mdi-arrow-left-bold-box</v-icon>
    </v-btn>
    <v-overlay
      v-if="currentImg.includes('sQ/op')"
      opacity="0.9"
      color="black"
      :value="overlay"
      z-index="30"
      ><div style="width: 100vw; height: 100vh" @click="overlay = false">
        <v-img
          class="ma-auto"
          contain
          height="100%"
          width="100%"
          :src="currentImg"
        />
        <v-btn
          color="transparent"
          fab
          fixed
          :ripple="true"
          bottom
          right
          @click.stop="downloadFile"
        >
          <v-img
            max-width="40px"
            max-height="40px"
            :src="require('@/assets/downloadBtn.svg')"
          />
        </v-btn>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    overlay: false,
    currentImg: "",
    codul: "-232321310",
    inputCod: "",
    ledImg: "",
    ledImgs: [
      "https://i.ibb.co/jypD5sF/led-black.png",
      "https://i.ibb.co/LzBSR1P/led-green.png",
      "https://i.ibb.co/4YRHjnV/led-red.png",
      "https://i.ibb.co/16nRv33/led-blue.png",
    ],
    downloadTablouImg: "",
  }),
  computed: {
    html() {
      return this.$store.getters["game/game"].html;
    },
    game() {
      return this.$store.getters["game/game"];
    },
  },
  methods: {
    getImg(e) {
      const coord = this.GetCoordinates(e, document.getElementById("imginile"));
      const x = coord[0];
      const y = coord[1];

      if (this.currentImg === this.html[1]) {
        const inside = this.insidePolygon(x, y, [
          [0.395, 0.71],
          [0.76, 0.857],
          [0.82, 0.525],
          [0.5, 0.413],
        ]);

        if (inside) {
          this.fetchImg("pozaServieta");
        }
      } else if (this.currentImg.includes("Nh/br")) {
        const inside = this.insidePolygon(x, y, [
          [0.4, 0.699],
          [0.439, 0.57],
          [0.53, 0.61],
          [0.49, 0.74],
        ]);

        if (inside) {
          this.fetchImg("pozaCod");
        }
      } else if (this.currentImg.includes("4jD/pi")) {
        const isOne = this.insidePolygon(x, y, [
          [0.29534883720930233, 0.31],
          [0.4046511627906977, 0.312],
          [0.39767441860465114, 0.408],
          [0.2930232558139535, 0.402],
        ]);

        if (isOne) return this.checkCod(1);

        const isTwo = this.insidePolygon(x, y, [
          [0.4418604651162791, 0.31],
          [0.5581395348837209, 0.31],
          [0.5534883720930233, 0.406],
          [0.44883720930232557, 0.404],
        ]);

        if (isTwo) return this.checkCod(2);

        const isThree = this.insidePolygon(x, y, [
          [0.6046511627906976, 0.31],
          [0.713953488372093, 0.312],
          [0.713953488372093, 0.406],
          [0.6023255813953489, 0.404],
        ]);

        if (isThree) return this.checkCod(3);

        const isFour = this.insidePolygon(x, y, [
          [0.29069767441860467, 0.448],
          [0.4, 0.452],
          [0.4, 0.544],
          [0.28837209302325584, 0.538],
        ]);

        if (isFour) return this.checkCod(4);

        const isFive = this.insidePolygon(x, y, [
          [0.4441860465116279, 0.454],
          [0.5581395348837209, 0.45],
          [0.5558139534883721, 0.542],
          [0.44651162790697674, 0.54],
        ]);

        if (isFive) return this.checkCod(5);

        const isSix = this.insidePolygon(x, y, [
          [0.6, 0.454],
          [0.713953488372093, 0.45],
          [0.713953488372093, 0.546],
          [0.5953488372093023, 0.542],
        ]);

        if (isSix) return this.checkCod(6);

        const isSeven = this.insidePolygon(x, y, [
          [0.29095354523227385, 0.5915789473684211],
          [0.4034229828850856, 0.5873684210526315],
          [0.4083129584352078, 0.6863157894736842],
          [0.28117359413202936, 0.68],
        ]);

        if (isSeven) return this.checkCod(7);

        const isEight = this.insidePolygon(x, y, [
          [0.4400977995110024, 0.5873684210526315],
          [0.5623471882640587, 0.5915789473684211],
          [0.5623471882640587, 0.6884210526315789],
          [0.43765281173594134, 0.6863157894736842],
        ]);

        if (isEight) return this.checkCod(8);

        const isNine = this.insidePolygon(x, y, [
          [0.6014669926650367, 0.5894736842105263],
          [0.7114914425427873, 0.5852631578947368],
          [0.7114914425427873, 0.6863157894736842],
          [0.5990220048899756, 0.6863157894736842],
        ]);

        if (isNine) return this.checkCod(9);

        const isZero = this.insidePolygon(x, y, [
          [0.4400977995110024, 0.728421052631579],
          [0.5550122249388753, 0.728421052631579],
          [0.5550122249388753, 0.8252631578947368],
          [0.4449877750611247, 0.8231578947368421],
        ]);

        if (isZero) return this.checkCod(0);

        const isRefresh = this.insidePolygon(x, y, [
          [0.5953488372093023, 0.732],
          [0.7116279069767442, 0.728],
          [0.7186046511627907, 0.828],
          [0.6, 0.822],
        ]);

        if (isRefresh) return this.checkCod(-1);
      } else if (this.currentImg.includes("sQ/op")) {
        this.overlay = true;
      }
    },
    FindPosition(oElement) {
      if (typeof oElement.offsetParent != "undefined") {
        for (
          var posX = 0, posY = 0;
          oElement;
          oElement = oElement.offsetParent
        ) {
          posX += oElement.offsetLeft;
          posY += oElement.offsetTop;
        }
        return [posX, posY];
      } else {
        return [oElement.x, oElement.y];
      }
    },
    GetCoordinates(e, refImg) {
      var PosX = 0;
      var PosY = 0;
      var ImgPos;
      ImgPos = this.FindPosition(refImg);
      if (!e) e = window.event;
      if (e.pageX || e.pageY) {
        PosX = e.pageX;
        PosY = e.pageY;
      } else if (e.clientX || e.clientY) {
        PosX =
          e.clientX +
          document.body.scrollLeft +
          document.documentElement.scrollLeft;
        PosY =
          e.clientY +
          document.body.scrollTop +
          document.documentElement.scrollTop;
      }

      return [
        (PosX - ImgPos[0]) / refImg.clientWidth,
        (PosY - ImgPos[1]) / refImg.clientHeight,
      ];
    },
    insidePolygon(x, y, vs) {
      let inside = false;

      for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        let xi = vs[i][0],
          yi = vs[i][1];
        let xj = vs[j][0],
          yj = vs[j][1];

        let intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }

      return inside;
    },
    fetchImg(poza) {
      this.inputCod = "";

      axios
        .post(
          `${this.$hostname}/games/game3TheRecruit`,
          { poza },
          { withCredentials: true }
        )
        .then(({ data }) => {
          if (data.info) {
            if (poza === "pozaTablou") {
              this.currentImg = data.info[0];
              this.downloadTablouImg = data.info[1];
            } else this.currentImg = data.info;
            if (poza === "pozaCod") this.ledImg = this.ledImgs[0];

            document.getElementById("imginile").style.visibility = "hidden";
            document
              .getElementById("imginile")
              .classList.remove("imgAnimation");

            setTimeout(() => {
              document.getElementById("imginile").style.visibility = "visible";
              document.getElementById("imginile").classList.add("imgAnimation");
            }, 40);
          }
        });
    },
    checkCod(newDigit) {
      if (newDigit < 0) {
        this.ledImg = this.ledImgs[3];
        setTimeout(() => {
          this.ledImg = this.ledImgs[0];
        }, 200);
        this.inputCod = "";
        return;
      }

      this.inputCod += newDigit;

      if (this.inputCod === this.codul) {
        return this.fetchImg("pozaTablou");
      }
      if (this.codul.startsWith(this.inputCod)) {
        this.ledImg = this.ledImgs[1];
        setTimeout(() => {
          this.ledImg = this.ledImgs[0];
        }, 200);
      } else {
        this.ledImg = this.ledImgs[2];
        setTimeout(() => {
          this.ledImg = this.ledImgs[0];
        }, 200);
      }
    },
    backImage() {
      if (this.currentImg.includes("Nh/br")) this.currentImg = this.html[1];
      else if (this.currentImg.includes("4jD/pi"))
        this.fetchImg("pozaServieta");
      else if (this.currentImg.includes("sQ/op")) this.fetchImg("pozaCod");
    },
    downloadFile() {
      fetch(this.downloadTablouImg)
        .then((res) => res.blob())
        .then((file) => {
          let tempUrl = URL.createObjectURL(file);
          const aTag = document.createElement("a");
          aTag.href = tempUrl;
          aTag.download = this.game.title + "-MasteryCase.png";
          document.body.appendChild(aTag);
          aTag.click();
          URL.revokeObjectURL(tempUrl);
          aTag.remove();
        })
        .catch(() => {
          alert("Failed to download file!");
        });
    },
  },
  created() {
    this.currentImg = this.html[1];
    this.codul = "73697995669440";
  },
};
</script>

<style scoped>
.btnservieta {
  margin-left: 45%;
  height: 32%;
  width: 37%;
  transform: rotate(20deg);
  color: transparent;
  background-color: transparent;
  cursor: default;
}

.btnservieta1 {
  transform: rotate(20deg);
  color: transparent;
  background-color: transparent;
  cursor: default;
}

.imgAnimation {
  animation: fadeIn 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#bridge {
  display: block;
  margin: 0 auto;
  background-image: url("https://i.ibb.co/RvMHBJJ/opened-briefcase.png");
}

#bridgeContainer {
  text-align: center;
}
</style>
