<template>
  <div>
    <app-bar currentPage="game"></app-bar>
    <div
      :style="{ height: `${windowHeight - 90}px`, width: '100%' }"
      class="d-flex"
      :class="`${isMobile ? 'flex-column' : 'overflowScroll scroll'} `"
    >
      <v-btn
        v-if="showLogo"
        rounded
        dark
        height="100px"
        width="200px"
        style="position: fixed; top: 30%; left: 50%; margin-left: -100px"
        @click="$router.push({ name: 'main' })"
        >MasteryCase <br />
        Home</v-btn
      >
      <div
        class="d-flex justify-center"
        :class="[isMobile ? 'align-center' : 'align-end']"
        style="height: 88px; position: absolute; top: 0%"
        :style="{
          left: '20%',
          width: '60%',
          fontSize: `${displaySize(35, 0.12)}px`,
        }"
      >
        <span class="mTitleGame text-truncate"> {{ game.title }}</span>
      </div>
      <div
        v-if="!game.html"
        :style="`${isMobile ? 'height: 93%; width: 100%' : 'width: 50%;'}`"
      >
        <loading-circle
          :position="'relative'"
          style="margin-left: 45%; margin-top: 25%"
        ></loading-circle>
      </div>
      <div
        v-else
        :style="`${isMobile ? 'height: 93%; width: 100%' : 'width: 50%;'}`"
      >
        <component :is="game.html[0]"></component>
      </div>
      <v-bottom-sheet
        v-if="isMobile"
        scrollable
        :content-class="keyboardMobileUp && isAndroid ? 'v-top-sheet' : ''"
        :persistent="keyboardMobileUp"
        overlay-opacity="0.6"
        style="height: 7%; position: fixed"
        v-model="sheet"
        max-width="450px"
        inset
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!(keyboardMobileUp && isAndroid)"
            width="100px"
            height="7%"
            color="#303336"
            elevation="20"
            style="margin: auto; margin-bottom: 0px"
            v-bind="attrs"
            v-on="on"
          >
            <img
              height="33px"
              width="15px"
              style="transform: rotate(90deg)"
              :src="require('@/assets/BackButton.svg')"
            />
          </v-btn>
        </template>
        <option-card
          :height="windowHeight"
          :keyboardAndroidUp="keyboardMobileUp && isAndroid"
        ></option-card>
      </v-bottom-sheet>
      <div v-else style="width: 35%">
        <option-card :height="windowHeight"></option-card>
      </div>
      <entry-dialog v-if="$store.state.game.openEntry"></entry-dialog>
    </div>
  </div>
</template>

<script>
import LoadingCircle from "../../../components/LoadingCircle.vue";
import OptionCard from "../components/OptionCard.vue";
import EntryDialog from "../components/dialogs/EntryDialog.vue";
import TheLetter from "../components/games/TheLetter.vue";
import HocusPocus from "../components/games/HocusPocus.vue";
import TheRecruit from "../components/games/TheRecruit.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  props: ["id"],
  components: {
    OptionCard,
    EntryDialog,
    AppBar,
    LoadingCircle,
    TheLetter,
    HocusPocus,
    TheRecruit,
  },
  data: () => ({
    sheet: true,
    showLogo: false,
  }),
  computed: {
    game() {
      return this.$store.getters["game/game"];
    },
    keyboardMobileUp() {
      if (this.windowHeight < screen.height * 0.65 || this.windowHeight < 250)
        return true;
      return false;
    },
  },
  watch: {
    game(val) {
      if (val.status !== undefined) this.showLogo = false;
    },
  },
  async created() {
    this.$store.commit("currentPage", { page: "game" });
    this.$store.state.game.error = false;
    setTimeout(() => {
      if (!localStorage.getItem("showAgain"))
        this.$store.state.game.openEntry = true;
    }, 400);
    if (this.game.status === undefined)
      await this.$store.dispatch("main/fetchGame", { id: this.id });
    setTimeout(() => {
      if (this.game.status === undefined) this.showLogo = true;
    }, 3000);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isMobile && to.name !== "solution" && this.sheet && !this.isIOS) {
      this.sheet = false;
      next(false);
    } else if (to.query.s) next("/");
    else next();
  },
};
</script>

<style scoped>
.overflowScroll {
  overflow-y: auto;
}
</style>
