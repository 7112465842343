<template>
  <v-dialog
    content-class="round-dialog"
    overlay-opacity="0.7"
    max-width="540px"
    v-model="$store.state.game.openEntry"
    persistent
  >
    <v-card min-height="270px" class="pa-4" color="#1d1f20">
      <div
        class="d-flex flex-column align-center"
        :class="[`px-${displaySize(5)}`]"
      >
        <p
          class="regularText text-center font-weight-medium pt-3 mb-0"
          style="font-size: 100%"
        >
          {{
            $store.state.game.game.status
              ? "The puzzle starts from here. It contains hidden messages, that lead to a Secret Code. In order to win the prize you need to be the first to solve the puzzle, by figuring out the Secret Code."
              : "The puzzle starts from here. It contains hidden messages, that lead to a Secret Code. You can solve the puzzle, by figuring out its Secret Code, but there will be no prize, as it has already been discovered."
          }}

          <!-- The puzzle starts from here. It contains hidden messages, that lead to
          a secret code. In order to win the prize you need to be the first to
          solve the puzzle, by figuring out the code. -->
        </p>
        <v-checkbox
          @blur="false"
          v-model="showAgain"
          label="Do not show this message again."
          color="white"
          class="align-self-start mb-10 mx-auto"
          hide-details
          dark
        ></v-checkbox>
        <v-btn
          class="btnText rounded-lg mb-4"
          style="font-size: 90%; font-weight: 900; text-transform: none"
          :width="`${displaySize(20, -0.1)}%`"
          small
          @click="closeDialog"
          >Ok
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    showAgain: false,
  }),

  methods: {
    closeDialog() {
      if (this.showAgain) localStorage.setItem("showAgain", this.showAgain);
      this.$store.state.game.openEntry = false;
    },
  },
};
</script>