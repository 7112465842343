var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-bar',{attrs:{"currentPage":"game"}}),_c('div',{staticClass:"d-flex",class:((_vm.isMobile ? 'flex-column' : 'overflowScroll scroll') + " "),style:({ height: ((_vm.windowHeight - 90) + "px"), width: '100%' })},[(_vm.showLogo)?_c('v-btn',{staticStyle:{"position":"fixed","top":"30%","left":"50%","margin-left":"-100px"},attrs:{"rounded":"","dark":"","height":"100px","width":"200px"},on:{"click":function($event){return _vm.$router.push({ name: 'main' })}}},[_vm._v("MasteryCase "),_c('br'),_vm._v(" Home")]):_vm._e(),_c('div',{staticClass:"d-flex justify-center",class:[_vm.isMobile ? 'align-center' : 'align-end'],staticStyle:{"height":"88px","position":"absolute","top":"0%"},style:({
        left: '20%',
        width: '60%',
        fontSize: ((_vm.displaySize(35, 0.12)) + "px"),
      })},[_c('span',{staticClass:"mTitleGame text-truncate"},[_vm._v(" "+_vm._s(_vm.game.title))])]),(!_vm.game.html)?_c('div',{style:(("" + (_vm.isMobile ? 'height: 93%; width: 100%' : 'width: 50%;')))},[_c('loading-circle',{staticStyle:{"margin-left":"45%","margin-top":"25%"},attrs:{"position":'relative'}})],1):_c('div',{style:(("" + (_vm.isMobile ? 'height: 93%; width: 100%' : 'width: 50%;')))},[_c(_vm.game.html[0],{tag:"component"})],1),(_vm.isMobile)?_c('v-bottom-sheet',{staticStyle:{"height":"7%","position":"fixed"},attrs:{"scrollable":"","content-class":_vm.keyboardMobileUp && _vm.isAndroid ? 'v-top-sheet' : '',"persistent":_vm.keyboardMobileUp,"overlay-opacity":"0.6","max-width":"450px","inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!(_vm.keyboardMobileUp && _vm.isAndroid)),expression:"!(keyboardMobileUp && isAndroid)"}],staticStyle:{"margin":"auto","margin-bottom":"0px"},attrs:{"width":"100px","height":"7%","color":"#303336","elevation":"20"}},'v-btn',attrs,false),on),[_c('img',{staticStyle:{"transform":"rotate(90deg)"},attrs:{"height":"33px","width":"15px","src":require('@/assets/BackButton.svg')}})])]}}],null,false,3502107386),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('option-card',{attrs:{"height":_vm.windowHeight,"keyboardAndroidUp":_vm.keyboardMobileUp && _vm.isAndroid}})],1):_c('div',{staticStyle:{"width":"35%"}},[_c('option-card',{attrs:{"height":_vm.windowHeight}})],1),(_vm.$store.state.game.openEntry)?_c('entry-dialog'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }