var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"content-class":"round-dialog","overlay-opacity":"0.7","max-width":"450px"},model:{value:(_vm.$store.state.game.openIncreasePrize),callback:function ($$v) {_vm.$set(_vm.$store.state.game, "openIncreasePrize", $$v)},expression:"$store.state.game.openIncreasePrize"}},[_c('v-card',{staticClass:"d-flex align-center flex-column py-6",class:[("px-" + (Math.ceil(_vm.displaySize(6))))],attrs:{"color":"#1d1f20","width":"450px"}},[_c('div',{staticClass:"d-flex align-center pb-3",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('p',{staticClass:"regularText font-weight-medium mb-0",style:({ fontSize: ((_vm.displaySize(1, 0.05)) + "em") })},[_vm._v(" Enter more money ")])]),_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"text-body-2",style:({ borderRadius: '10px' }),attrs:{"color":"white","label":("min " + (_vm.amountPay >= _vm.game.minPay ? 1 : _vm.game.minPay) + "€"),"outlined":"","dense":"","dark":"","autofocus":"","required":"","min":_vm.amountPay >= _vm.game.minPay ? 1 : _vm.game.minPay,"type":"number"},model:{value:(_vm.addPrize),callback:function ($$v) {_vm.addPrize=$$v},expression:"addPrize"}}),_c('v-tooltip',{attrs:{"max-width":"300px","right":_vm.isMobile ? false : true,"left":_vm.isMobile ? true : false,"transition":("slide-x-" + (_vm.isMobile ? '' : 'reverse') + "-transition"),"color":"rgb(47,47,47, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:[("ml-" + (Math.ceil(_vm.displaySize(3))))],attrs:{"dense":"","color":"rgb(200, 200, 200)"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-variant")])]}}])},[_c('span',{class:[!_vm.isMobile ? 'tooltipText' : '', 'ma-0']},[_vm._v(" Here you can enter a amount, in order to increase your prize.")])])],1)],1),_c('div',{staticClass:"d-flex align-center pb-3",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('p',{staticClass:"regularText font-weight-medium mb-0",style:({ fontSize: ((_vm.displaySize(1, 0.05)) + "em") })},[_vm._v(" Value added to prize ")])]),_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"text-body-2",style:({ borderRadius: '10px' }),attrs:{"color":"white","background-color":"#3a3a3a","outlined":"","dense":"","value":_vm.totalPrize + '€',"dark":"","required":"","disabled":""}}),_c('v-tooltip',{attrs:{"max-width":"300px","right":_vm.isMobile ? false : true,"left":_vm.isMobile ? true : false,"transition":("slide-x-" + (_vm.isMobile ? '' : 'reverse') + "-transition"),"color":"rgb(47,47,47, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:[("ml-" + (Math.ceil(_vm.displaySize(3))))],attrs:{"dense":"","color":"rgb(200, 200, 200)"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-variant")])]}}])},[_c('span',{class:[!_vm.isMobile ? 'tooltipText' : '', 'ma-0']},[_vm._v(" This is the value you add to the current prize. It is determined multiplying the amount entered with the quota ("+_vm._s(_vm.game.multiply)+"x).")])])],1)],1),_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('p',{staticClass:"regularText font-weight-medium mb-0",style:({ fontSize: ((_vm.displaySize(1, 0.05)) + "em") })},[_vm._v(" New prize ")])]),_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"text-body-2",style:({ borderRadius: '10px' }),attrs:{"color":"white","background-color":"#3a3a3a","outlined":"","dense":"","value":_vm.totalPrize * _vm.game.multiply + '€',"dark":"","required":"","disabled":""}}),_c('v-tooltip',{attrs:{"max-width":"300px","right":_vm.isMobile ? false : true,"left":_vm.isMobile ? true : false,"transition":("slide-x-" + (_vm.isMobile ? '' : 'reverse') + "-transition"),"color":"rgb(47,47,47, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:[("ml-" + (Math.ceil(_vm.displaySize(3))))],attrs:{"dense":"","color":"rgb(200, 200, 200)"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-variant")])]}}])},[_c('span',{class:[!_vm.isMobile ? 'tooltipText' : '', 'ma-0']},[_vm._v(" This will be your new potential prize after making the purchase. ")])])],1)],1),_c('pay-advertisment',{staticClass:"mt-8"}),_c('v-btn',{staticClass:"rounded-lg text-capitalize text-truncate",attrs:{"disabled":!_vm.addPrize ||
        _vm.totalPrize < _vm.game.minPay ||
        _vm.totalPrize > _vm.game.maxPay ||
        _vm.isLoading,"dark":"","color":"green","width":"150px"},on:{"click":_vm.increasePrize}},[_vm._v("Add "+_vm._s(_vm.addPrize || 0)+"€ "),_c('span',{staticStyle:{"letter-spacing":"0.1px","font-size":"0.85em"}},[_vm._v("("+_vm._s(_vm.addPrize ? (4.94 * _vm.addPrize).toFixed(2) : 0)+" lei)")])]),_c('img',{attrs:{"height":"55","width":"40%","src":require('@/assets/VivaLogo.svg')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }