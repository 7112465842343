<template>
  <div>
    <div v-for="i in game.passwordLen.length" :key="i">
      <div class="d-flex align-center">
        <div class="password-img mx-1"></div>
        <div
          v-for="id of game.passwordLen[i - 1]"
          :key="id"
          style="height: 35px"
        >
          <input
            type="text"
            v-if="id !== -1"
            :style="{ width: `${displaySize(22, 0.03)}px` }"
            :id="`otp-${id}`"
            maxlength="1"
            @focus="$event.target.select()"
            @keydown="keyDownEvent"
            @keyup="keyUpEvent"
            @keydown.space.prevent
            autocomplete="off"
          />
          <div v-else style="width: 20px"></div>
        </div>
        <v-img
          v-if="i === game.passwordLen.length"
          @click="postPassword"
          class="password-img mx-1"
          :class="[
            isMobile
              ? 'onClick'
              : `${isSafariMAC ? 'safariPrimPlan' : 'primPlan'}`,
          ]"
          :src="require('@/assets/sendPassword.svg')"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      contorLeter: 0,
      keyPressed: {},
    };
  },
  computed: {
    game() {
      return this.$store.getters["game/game"];
    },
    passLength() {
      const p = this.game.passwordLen;
      return p[p.length - 1][p[p.length - 1].length - 1];
    },
  },
  methods: {
    postPassword() {
      if (this.isMobile)
        document.getElementById(`otp-${this.passLength}`).select();

      if (this.$store.getters["game/error"])
        this.$store.state.game.error = false;
      // this timeOut is for shake the error
      setTimeout(() => {
        let password = "";
        for (let i = 1; i <= this.passLength; i++) {
          const currInput = document.getElementById(`otp-${i}`);
          if (!currInput.value.length) {
            this.$store.state.game.error = true;
            this.$store.state.game.messageError = "Fill in all the spaces!";
            currInput.select();
            return;
          }
          password += currInput.value;
        }
        password = password.toLowerCase();
        this.$store.dispatch("game/postPassword", { password });
      }, 50);
    },
    keyDownEvent(ev) {
      if (this.$store.getters["game/error"])
        this.$store.state.game.error = false;
      const id = parseInt(ev.srcElement.id.split("-")[1]);
      const value = document.getElementById(`otp-${id}`).value;
      const len = value.length;
      if (len >= 2) document.getElementById(`otp-${id}`).value = value[1];
      if (ev.code === "Enter") return;
      else if (ev.code === "Space") return;
      else if (ev.code === "ArrowLeft") return;
      else if (ev.code === "ArrowRight" && id !== this.passLength) return;
      else if (ev.code === "Backspace" || ev.keyCode === 8) {
        if (len) document.getElementById(`otp-${id}`).select();
        else if (id !== 1) document.getElementById(`otp-${id - 1}`).select();
        return;
      } else if (ev.code === "Tab") return;
      this.keyPressed[ev.code] = true;
      if (this.contorLeter > 0 && id !== this.passLength)
        document.getElementById(`otp-${id + 1}`).select();
      this.contorLeter++;
    },
    keyUpEvent(ev) {
      const id = parseInt(ev.srcElement.id.split("-")[1]);
      const value = document.getElementById(`otp-${id}`).value;
      const len = value.length;
      if (len >= 2) document.getElementById(`otp-${id}`).value = value[1];
      if (ev.code === "Enter") {
        this.postPassword();
        return;
      } else if (ev.code === "Space") return;
      else if (ev.code === "ArrowLeft") {
        if (id !== 1) document.getElementById(`otp-${id - 1}`).select();
        return;
      } else if (ev.code === "ArrowRight" && id !== this.passLength) {
        document.getElementById(`otp-${id + 1}`).select();
        return;
      } else if (ev.code === "Backspace" || ev.keyCode === 8) return;
      else if (ev.code === "Tab") return;
      this.contorLeter--;
      if (this.contorLeter == 0 && id !== this.passLength)
        document.getElementById(`otp-${id + 1}`).select();
      if (this.contorLeter < 0) this.contorLeter = 0;
      this.keyPressed[ev.code] = undefined;
      for (const key in this.keyPressed) if (this.keyPressed[key]) return;
      this.contorLeter = 0;
    },
    // keyEvent(ev) {
    //   if (this.$store.getters["game/error"])
    //     this.$store.state.game.error = false;
    //   const id = parseInt(ev.srcElement.id.split("-")[1]);
    //   const value = document.getElementById(`otp-${id}`).value;
    //   const len = value.length;
    //   if (len >= 2) document.getElementById(`otp-${id}`).value = value[1];
    //   if (ev.code === "Enter") {
    //     this.postPassword();
    //     return;
    //   } else if (ev.code === "ArrowLeft" && id !== 1) {
    //     document.getElementById(`otp-${id - 1}`).select();
    //     return;
    //   } else if (ev.code === "ArrowRight" && id !== this.passLength) {
    //     document.getElementById(`otp-${id + 1}`).select();
    //     return;
    //   } else if (ev.code === "Backspace") {
    //     if (len) document.getElementById(`otp-${id}`).select();
    //     else if (id !== 1) document.getElementById(`otp-${id - 1}`).select();
    //     return;
    //   } else if (ev.code === "Tab") return;
    //   // if (len) {
    //   //   if (id !== this.passLength)
    //   //     document.getElementById(`otp-${id + 1}`).select();
    //   // } else if (ev.keyCode === 8 && id !== 1)
    //   //   document.getElementById(`otp-${id - 1}`).select();
    // },
  },
};
</script>

<style scoped>
input {
  margin: 2px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: arimo;
  font-size: 1.2em;
  background: #eef2f3;
  text-transform: uppercase;
}
.password-img {
  height: 25px;
  width: 30px;
  cursor: pointer;
}
</style>