<template>
  <v-dialog
    content-class="round-dialog"
    overlay-opacity="0.7"
    max-width="450px"
    v-model="$store.state.game.openIncreasePrize"
  >
    <v-card
      color="#1d1f20"
      width="450px"
      class="d-flex align-center flex-column py-6"
      :class="[`px-${Math.ceil(displaySize(6))}`]"
    >
      <div style="width: 100%" class="d-flex align-center pb-3">
        <v-col cols="8" class="pa-0">
          <p
            class="regularText font-weight-medium mb-0"
            :style="{ fontSize: `${displaySize(1, 0.05)}em` }"
          >
            Enter more money
          </p>
        </v-col>
        <v-col cols="4" class="d-flex pa-0">
          <v-text-field
            class="text-body-2"
            color="white"
            :style="{ borderRadius: '10px' }"
            :label="`min ${amountPay >= game.minPay ? 1 : game.minPay}€`"
            outlined
            dense
            dark
            autofocus
            required
            v-model="addPrize"
            :min="amountPay >= game.minPay ? 1 : game.minPay"
            type="number"
          ></v-text-field>
          <v-tooltip
            max-width="300px"
            :right="isMobile ? false : true"
            :left="isMobile ? true : false"
            :transition="`slide-x-${isMobile ? '' : 'reverse'}-transition`"
            color="rgb(47,47,47, 1)"
            ><template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                dense
                color="rgb(200, 200, 200)"
                :class="[`ml-${Math.ceil(displaySize(3))}`]"
                >mdi-information-variant</v-icon
              ></template
            ><span :class="[!isMobile ? 'tooltipText' : '', 'ma-0']">
              Here you can enter a amount, in order to increase your
              prize.</span
            >
          </v-tooltip>
        </v-col>
      </div>
      <div style="width: 100%" class="d-flex align-center pb-3">
        <v-col cols="8" class="pa-0">
          <p
            class="regularText font-weight-medium mb-0"
            :style="{ fontSize: `${displaySize(1, 0.05)}em` }"
          >
            Value added to prize
          </p>
        </v-col>
        <v-col cols="4" class="d-flex pa-0">
          <v-text-field
            class="text-body-2"
            color="white"
            background-color="#3a3a3a"
            :style="{ borderRadius: '10px' }"
            outlined
            dense
            :value="totalPrize + '€'"
            dark
            required
            disabled
          ></v-text-field>
          <v-tooltip
            max-width="300px"
            :right="isMobile ? false : true"
            :left="isMobile ? true : false"
            :transition="`slide-x-${isMobile ? '' : 'reverse'}-transition`"
            color="rgb(47,47,47, 1)"
            ><template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                dense
                color="rgb(200, 200, 200)"
                :class="[`ml-${Math.ceil(displaySize(3))}`]"
                >mdi-information-variant</v-icon
              ></template
            ><span :class="[!isMobile ? 'tooltipText' : '', 'ma-0']">
              This is the value you add to the current prize. It is determined
              multiplying the amount entered with the quota ({{
                game.multiply
              }}x).</span
            >
          </v-tooltip>
        </v-col>
      </div>
      <div style="width: 100%" class="d-flex align-center">
        <v-col cols="8" class="pa-0">
          <p
            class="regularText font-weight-medium mb-0"
            :style="{ fontSize: `${displaySize(1, 0.05)}em` }"
          >
            <!-- Total prize ({{ game.multiply }}x) -->
            New prize
          </p>
        </v-col>
        <v-col cols="4" class="d-flex pa-0">
          <v-text-field
            class="text-body-2"
            color="white"
            background-color="#3a3a3a"
            :style="{ borderRadius: '10px' }"
            outlined
            dense
            :value="totalPrize * game.multiply + '€'"
            dark
            required
            disabled
          ></v-text-field>
          <v-tooltip
            max-width="300px"
            :right="isMobile ? false : true"
            :left="isMobile ? true : false"
            :transition="`slide-x-${isMobile ? '' : 'reverse'}-transition`"
            color="rgb(47,47,47, 1)"
            ><template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                dense
                color="rgb(200, 200, 200)"
                :class="[`ml-${Math.ceil(displaySize(3))}`]"
                >mdi-information-variant</v-icon
              ></template
            ><span :class="[!isMobile ? 'tooltipText' : '', 'ma-0']">
              This will be your new potential prize after making the purchase.
            </span>
          </v-tooltip>
        </v-col>
      </div>
      <pay-advertisment class="mt-8"></pay-advertisment>
      <v-btn
        :disabled="
          !addPrize ||
          totalPrize < game.minPay ||
          totalPrize > game.maxPay ||
          isLoading
        "
        dark
        color="green"
        width="150px"
        class="rounded-lg text-capitalize text-truncate"
        @click="increasePrize"
        >Add {{ addPrize || 0 }}€
        <span style="letter-spacing: 0.1px; font-size: 0.85em"
          >({{ addPrize ? (4.94 * addPrize).toFixed(2) : 0 }} lei)</span
        >
      </v-btn>
      <img height="55" width="40%" :src="require('@/assets/VivaLogo.svg')" />
    </v-card>
  </v-dialog>
</template>

<script>
import PayAdvertisment from "@/components/PayAdvertisment.vue";

export default {
  components: {
    PayAdvertisment,
  },
  data: () => ({
    addPrize: 1,
    isLoading: false,
  }),
  computed: {
    game() {
      return this.$store.getters["game/game"];
    },
    amountPay() {
      return this.$store.getters["game/amountPay"];
    },
    totalPrize() {
      if (isNaN(this.addPrize) || this.addPrize === "")
        return this.$store.state.game.amountPay;
      return this.$store.state.game.amountPay + parseInt(this.addPrize);
    },
  },
  watch: {
    addPrize(val) {
      if (val[0] === "0") this.addPrize = "";
      else this.addPrize = val.replace(".", "");
    },
  },
  methods: {
    async increasePrize() {
      this.isLoading = true;
      const path = `prizePay/${this.game._id}?addAmount=${parseInt(
        this.addPrize
      )}`;

      let orderCode = await this.$store.dispatch("makePayment", { path });
      if (orderCode) this.goExternApp(this.$vivaCheckout + orderCode);

      this.isLoading = false;
    },
  },
  created() {
    if (this.amountPay < this.game.minPay) this.addPrize = this.game.minPay;
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> label {
  font-size: 13px;
}
.v-text-field--outlined >>> button {
  font-size: 18px;
}

.v-text-field--outlined {
  height: 40px;
}
/* v-text-field__details */
</style>
