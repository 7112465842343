<template>
  <v-dialog
    content-class="round-dialog"
    overlay-opacity="0.7"
    :max-width="solution.html ? '1000px' : '450px'"
    v-model="$store.state.game.openAnswer"
  >
    <v-card min-height="170px" color="#1d1f20">
      <div v-if="game.status" class="d-flex flex-column align-center">
        <p
          class="regularText text-center font-weight-medium pa-5"
          style="font-size: 1.2em"
        >
          You will be able to view the solution for
          <span class="font-weight-bold">
            {{ $store.getters["game/game"].title }}</span
          >
          as soon as someone discovers the Secret Code.
        </p>
        <v-btn
          class="btnText rounded-lg mb-7"
          style="font-size: 90%; font-weight: 900; text-transform: none"
          width="25%"
          small
          @click="$store.state.game.openAnswer = false"
          >Ok
        </v-btn>
      </div>
      <div
        v-else-if="solution === 'No Paid Solution!'"
        class="d-flex flex-column align-center"
      >
        <p
          class="regularText text-center font-weight-medium px-4 pt-5 mb-9"
          style="font-size: 1em"
        >
          Unlock the video solution that reveals in detail the solving steps for
          discovering the Secret Code of
          <span class="font-weight-bold text-truncate">
            {{ $store.getters["game/game"].title }}.</span
          >
        </p>
        <pay-advertisment style="width: 85%"></pay-advertisment>
        <v-btn
          dark
          :disabled="isLoading"
          class="btnText rounded-lg"
          style="font-size: 90%; font-weight: 600; text-transform: none"
          width="60%"
          min-width="200px"
          color="#006f1f"
          small
          @click="paySolution"
          >Unlock for {{ $store.getters["game/game"].solutionPay }}€<span
            style="letter-spacing: 0.1px; font-size: 0.85em"
            >({{
              (4.94 * $store.getters["game/game"].solutionPay).toFixed(2)
            }}
            lei)</span
          >
        </v-btn>
        <img
          height="30"
          width="40%"
          :src="require('@/assets/VivaLogo.svg')"
          class="my-3"
        />
      </div>
      <!-- <div v-else class="d-flex flex-column">
        <p
          class="regularText text-center font-weight-medium pt-3 mb-0"
          style="font-size: 1.5em"
        >
          Solution
        </p>
        <hr class="breakline my-2 align-self-center" style="width: 65%" />

        <div class="d-flex flex-column align-center mb-6">
          <iframe
            class="px-3"
            :src="solution.html"
            width="100%"
            :height="isMobile ? '270px' : '480px'"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            title="The Letter"
          ></iframe>
        </div>
        <v-btn
          v-if="isMobile"
          class="align-self-end"
          text
          fab
          absolute
          dark
          style="top: 2%; right: 2%"
          x-small
          @click="$store.state.game.openAnswer = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div> -->
    </v-card>
  </v-dialog>
</template>

<script>
import PayAdvertisment from "@/components/PayAdvertisment.vue";

export default {
  components: { PayAdvertisment },
  props: ["solution"],
  data: () => ({
    isLoading: false,
  }),
  computed: {
    game() {
      return this.$store.getters["game/game"];
    },
  },
  methods: {
    async paySolution() {
      this.isLoading = true;
      const path = `solutionPay/${this.$store.getters["game/game"]._id}`;

      let orderCode = await this.$store.dispatch("makePayment", { path });
      if (orderCode) this.goExternApp(this.$vivaCheckout + orderCode);

      this.isLoading = false;
      // this.$store.state.game.openAnswer = false;
    },
  },
  created() {
    window.addEventListener("orientationchange", () => {
      this.$store.state.game.openAnswer = false;
    });
  },
};
</script>
