<template>
  <div
    :style="{ paddingLeft: isMobile ? '0px' : displaySize(200, 0.4) + 'px' }"
    style="width: 100%"
  >
    <v-img
      :width="
        $vuetify.breakpoint.name === 'xl' ? '600px' : displaySize(600, 0.05)
      "
      :height="
        $vuetify.breakpoint.name === 'xl' ? '700px' : displaySize(500, 0.05)
      "
      class="ma-auto"
      :class="[`mt-${Math.ceil(displaySize(14, 0.1))}`]"
      @click="overlay = true"
      style="cursor: pointer"
      :src="html[1]"
    />
    <v-overlay opacity="0.9" color="black" :value="overlay" z-index="30"
      ><div style="width: 100vw; height: 100vh" @click="overlay = false">
        <v-img
          class="ma-auto"
          contain
          height="100%"
          width="100%"
          :src="html[1]"
        />
        <v-btn
          color="transparent"
          fab
          :loading="isDownloading"
          fixed
          :ripple="true"
          bottom
          right
          @click.stop="downloadFile"
        >
          <v-img
            max-width="40px"
            max-height="40px"
            :src="require('@/assets/downloadBtn.svg')"
          />
        </v-btn>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    overlay: false,
    isDownloading: false,
  }),
  computed: {
    html() {
      return this.$store.getters["game/game"].html;
    },
    game() {
      return this.$store.getters["game/game"];
    },
  },
  methods: {
    sendEvent(info) {
      axios.post(
        `${this.$hostname}/games/gameEvents`,
        { info },
        { withCredentials: true }
      );
    },
    downloadFile() {
      this.isDownloading = true;
      fetch(this.html[1])
        .then((res) => res.blob())
        .then((file) => {
          let tempUrl = URL.createObjectURL(file);
          const aTag = document.createElement("a");
          aTag.href = tempUrl;
          aTag.download = this.game.title + "-MasteryCase.png";
          document.body.appendChild(aTag);
          aTag.click();
          URL.revokeObjectURL(tempUrl);
          aTag.remove();
          this.isDownloading = false;

          this.sendEvent(`Downloaded ${this.html[1]} image!`);
        })
        .catch(() => {
          alert("Failed to download file!");
          this.isDownloading = false;

          this.sendEvent(`Failed to download ${this.html[1]} image!`);
        });
    },
  },
};
</script>
