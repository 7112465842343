<template>
  <v-dialog
    content-class="round-dialog"
    overlay-opacity="0.7"
    :max-width="hints === 'No Paid Hints!' || !hints.length ? '350px' : '450px'"
    v-model="$store.state.game.openHints"
  >
    <v-card min-height="170px" color="#1d1f20">
      <div
        v-if="hints === 'No Paid Hints!'"
        class="d-flex flex-column align-center"
      >
        <p
          class="regularText text-center font-weight-medium pt-5 mb-5"
          style="font-size: 1.2em"
        >
          Hints for
          <span class="font-weight-bold">
            {{ $store.getters["game/game"].title }}</span
          >
          <br />
          are available!
        </p>
        <pay-advertisment style="width: 85%"></pay-advertisment>
        <v-btn
          dark
          :disabled="isLoading"
          class="btnText rounded-lg"
          style="font-size: 90%; font-weight: 600; text-transform: none"
          width="60%"
          min-width="200px"
          color="#006f1f"
          small
          @click="payHints"
          >Unlock for {{ $store.getters["game/game"].hintsPay }}€
          <span style="letter-spacing: 0.1px; font-size: 0.85em"
            >({{
              (4.94 * $store.getters["game/game"].hintsPay).toFixed(2)
            }}
            lei)</span
          >
        </v-btn>
        <img
          height="30"
          width="40%"
          :src="require('@/assets/VivaLogo.svg')"
          class="my-3"
        />
      </div>
      <div class="d-flex flex-column align-center" v-else-if="!hints.length">
        <p
          class="regularText text-center font-weight-medium pt-5 mb-9"
          style="font-size: 1.2em"
        >
          Hints for
          <span class="font-weight-bold">
            {{ $store.getters["game/game"].title }}</span
          >
          <br />
          are not available.
        </p>
        <v-btn
          class="btnText rounded-lg"
          style="font-size: 90%; font-weight: 900; text-transform: none"
          width="25%"
          small
          @click="$store.state.game.openHints = false"
          >Ok
        </v-btn>
      </div>
      <div class="d-flex flex-column" v-else>
        <p
          class="regularText text-center font-weight-medium pt-3 mb-0"
          style="font-size: 1.2em"
        >
          Hints
        </p>
        <hr class="breakline my-2 align-self-center" style="width: 65%" />
        <div v-for="hint in hints" :key="hint._id" v-html="hint.html"></div>
        <v-btn
          v-if="isMobile"
          class="align-self-end"
          text
          fab
          absolute
          dark
          style="top: 2%; right: 2%"
          x-small
          @click="$store.state.game.openHints = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PayAdvertisment from "@/components/PayAdvertisment.vue";

export default {
  props: ["hints"],
  components: { PayAdvertisment },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    async payHints() {
      this.isLoading = true;
      const path = `hintsPay/${this.$store.getters["game/game"]._id}`;

      let orderCode = await this.$store.dispatch("makePayment", { path });
      if (orderCode) this.goExternApp(this.$vivaCheckout + orderCode);

      this.isLoading = false;
      // this.$store.state.game.openHints = false;
    },
  },
};
</script>

<style scoped>
</style>
