<template>
  <v-card
    v-if="game.status !== undefined"
    :min-width="isMobile ? '' : '400px'"
    :width="isMobile ? '100%' : ''"
    :max-width="isMobile ? '' : '450px'"
    :max-height="isMobile ? `${windowHeight * 0.8}` : ''"
    class="d-flex align-center flex-column py-6 noScroll"
    style="margin: auto"
    :style="{
      borderRadius:
        isMobile && !keyboardAndroidUp ? '25px 25px 0px 0px' : '25px',
      overflowY: `${isMobile ? 'scroll' : 'hidden'}`,
      marginTop: `${isMobile || '25%'}`,
    }"
    color="#242628"
  >
    <p class="robotoText white--text mb-1" style="letter-spacing: 4px">
      SECRET CODE
    </p>
    <otp-input></otp-input>
    <p
      :style="{ opacity: $store.getters['game/error'] ? 1 : 0 }"
      class="red--text font-weight-bold text-caption mb-0"
      :class="$store.getters['game/error'] ? 'h-shake' : ''"
    >
      {{ $store.getters["game/messageError"] }}&nbsp;
    </p>
    <!-- <div
      v-if="game.status"
      style="width: 100%; background-color: #3d3d3d; border-radius: 0px"
      class="mt-2 mb-10"
    >
      <div class="d-flex align-center justify-space-between py-2 px-3">
        <p
          style="color: white; font-size: 0.9em"
          class="regularText font-weight-medium my-auto"
        >
          Amount entered:
        </p>
        <p style="color: white" class="font-weight-medium my-auto">
          {{ $store.state.game.amountPay }}€
        </p>
      </div>
      <div class="d-flex align-center justify-space-between py-2 px-3">
        <p
          style="color: white; font-size: 0.9em"
          class="regularText text-center font-weight-medium my-auto"
        >
          Prize ({{ game.multiply }}x):
        </p>
        <p style="color: white" class="font-weight-medium my-auto">
          {{ game.multiply * this.$store.state.game.amountPay }}€
        </p>
      </div>
    </div> -->
    <div
      v-if="game.status"
      class="mt-2 mb-10 d-flex flex-column align-center"
      style="background-color: #3d3d3d; width: 100%"
    >
      <p
        style="color: white; font-size: 0.9em"
        class="regularText font-weight-medium px-5 pt-3"
      >
        Be the first to solve this puzzle and win
        {{ formatMoney(game.maxPay * game.multiply) }}€.
      </p>
    </div>
    <div v-else class="mt-2 mb-10" style="background-color: #3d3d3d">
      <p
        style="text-align: justify; color: white; font-size: 0.9em"
        class="regularText font-weight-medium px-14 pt-3"
      >
        This puzzle was solved on
        <span class="font-weight-black">{{ getDate(game.winner.date) }}</span>
        by
        <span class="font-weight-black">{{ game.winner.username }}. </span>
        Check out the solution below.
      </p>
    </div>
    <!-- <v-btn
      v-if="game.status"
      :disabled="game.maxPay <= $store.state.game.amountPay"
      dark
      class="btnText rounded-lg mb-3"
      style="font-size: 1em; font-weight: 600; width: 63.5%"
      color="#3d3d3d"
      small
      @click="$store.state.game.openIncreasePrize = true"
    >
      <div class="d-flex align-center justify-center">
        <img
          height="18px"
          class="mr-2"
          :src="require('@/assets/AddMorePlus.svg')"
        />
        <div>INCREASE PRIZE</div>
      </div>
    </v-btn> -->
    <div class="d-flex justify-space-between" style="width: 63.5%">
      <v-btn
        dark
        class="btnText rounded-lg"
        style="font-size: 1em; font-weight: 600; width: 47%"
        color="#3d3d3d"
        small
        @click="openHints"
        >HINTS</v-btn
      >
      <v-btn
        dark
        class="btnText rounded-lg"
        style="font-size: 1em; font-weight: 600; width: 47%"
        color="#3d3d3d"
        small
        @click="openAnswer"
        >Solution</v-btn
      >
    </div>
    <hint-dialog :hints="hints"></hint-dialog>
    <answer-dialog :solution="answer"></answer-dialog>
    <increase-prize v-if="$store.state.game.openIncreasePrize"></increase-prize>
    <win-dialog v-if="$store.state.game.openWin"></win-dialog>
  </v-card>
</template>

<script>
import HintDialog from "./dialogs/HintDialog.vue";
import IncreasePrize from "./dialogs/IncreasePrize.vue";
import AnswerDialog from "./dialogs/AnswerDialog.vue";
import WinDialog from "./dialogs/WinDialog.vue";
import OtpInput from "../components/OtpInput.vue";

export default {
  props: ["height", "keyboardAndroidUp"],
  components: {
    HintDialog,
    IncreasePrize,
    AnswerDialog,
    WinDialog,

    OtpInput,
  },
  data() {
    return {
      hints: [],
      answer: {},
    };
  },
  computed: {
    game() {
      return this.$store.getters["game/game"];
    },
  },
  methods: {
    async openHints() {
      if (this.hints.length) {
        this.$store.state.game.openHints = true;
        return;
      }
      const id = this.$route.params.id;
      this.hints = await this.$store.dispatch("game/fetchHints", { id });
    },
    async openAnswer() {
      if (this.game.status && this.game.status !== 0) {
        this.$store.state.game.openAnswer = true;
        return;
      }
      const id = this.$route.params.id;
      this.answer = await this.$store.dispatch("game/fetchAnswer", { id });

      if (this.answer.html)
        this.$router.push({
          name: "solution",
          params: { id: this.game._id, answer: this.answer },
        });
      else this.$store.state.game.openAnswer = true;
    },
    formatMoney(n) {
      return (Math.round(n * 100) / 100).toLocaleString();
    },
  },
  created() {
    const fromRoute = this.$store.getters["previousRoute"].name;
    if (fromRoute === "news" || fromRoute === null)
      if (this.$route.query.openSolution === "1") {
        this.openAnswer();
      } else if (this.$route.query.openHints === "1") {
        this.openHints();
      }
  },
};
</script>

<style scoped>
.h-shake {
  animation: horizontal-shaking 0.35s normal;
}
@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>