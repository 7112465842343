<template>
  <div>
    <v-dialog
      content-class="round-dialog"
      overlay-opacity="0.7"
      :max-width="!game.status ? '400px' : '500px'"
      :persistent="!game.status ? false : true"
      v-model="$store.state.game.openWin"
    >
      <v-card min-height="170px" color="#1d1f20">
        <div v-if="!game.status" class="d-flex flex-column align-center pa-6">
          <p
            class="regularText text-center font-weight-medium pt-3 mb-9"
            style="font-size: 100%"
          >
            Good job! Your answer is corect! This puzzle has already been
            solved.
            <br />
            <br />
            Check out other puzzles to win real money prizes!
          </p>
          <v-btn
            class="btnText rounded-lg"
            style="font-size: 90%; font-weight: 900; text-transform: none"
            width="25%"
            small
            @click="$store.state.game.openWin = false"
            >Ok
          </v-btn>
        </div>
        <div v-else class="d-flex align-center flex-column pa-6">
          <p
            class="regularText text-center font-weight-medium py-5"
            style="font-size: 100%"
          >
            Congratulations
            <span class="font-weight-bold">{{ user.username }}</span
            >! <br />
            Your answer for
            <span class="font-weight-bold">{{ game.title }}</span> is correct!
          </p>
          <img
            :src="require('@/assets/trofeu.svg')"
            height="75px"
            width="75px"
          />
          <p
            class="regularText text-center font-weight-medium py-5"
            style="font-size: 90%"
          >
            You are now part of the Mastery Case Winners. We will contact you
            via your email ({{ user.email }}) to redeem your prize (<span
              class="font-weight-bold"
              style="color: green"
              >{{
                game.multiply * amountPay || game.multiply * game.maxPay
              }}€</span
            >). <br />
            Well played!
          </p>
          <v-btn
            class="btnText rounded-lg mt-2 mb-5"
            style="font-size: 90%; font-weight: 900; text-transform: none"
            :width="`${displaySize(30, -0.1)}%`"
            small
            @click="$router.go()"
            >Let's go!
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- <v-overlay v-else
      ><img
        height="753px"
        :width="1519"
        :src="require('@/assets/gif/OrizontalWin.gif')"
    /></v-overlay> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    // endWinGif: false,
  }),
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    game() {
      return this.$store.getters["game/game"];
    },
    amountPay() {
      return this.$store.getters["game/amountPay"];
    },
  },
  // created() {
  //   setTimeout(() => {
  //     this.endWinGif = true;
  //   }, 3500);
  // },
};
</script>
